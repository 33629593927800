import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { UserInfo } from "../models/user-info.model";
import { CuAccessData } from "src/app/modules/Auth/models/auth.model";

import { LocalStorageService } from "./local-storage.service";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    userEmail: string = '';

    userInfo!: UserInfo;
    cuAccessData: CuAccessData[] = [];
    selectedCreditUnion!: CuAccessData;

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService
    ) { }

    getUserCU() {
        this.http.get<CuAccessData[]>(`/user/available-credit-unions`).subscribe(res => {
            this.cuAccessData = res.map((obj) => {
                if (obj.participantName === "Participant_Name") {
                    obj.modifiedName = obj.participantName;
                } else {
                    obj.modifiedName = `${obj.participantName} (${obj.charterNumber})`;
                }
                return obj;
            }).sort((a, b) => a.participantName.localeCompare(b.participantName));
            this.selectCreditUnion();
        });
    }

    saveNewCU(charterNumber: string) {
        return this.http.post(`/user/select-credit-union/${charterNumber}`, null);
    }

    selectCreditUnion() {
        this.selectedCreditUnion = this.cuAccessData.find(value => value.charterNumber === this.userInfo?.selectedCuCharterNumber) as CuAccessData;

        if (!this.selectedCreditUnion) {
            this.selectedCreditUnion = this.cuAccessData[0];
            this.userInfo.selectedCuCharterNumber = this.selectedCreditUnion.charterNumber;
            this.userInfo.selectedCuName = this.selectedCreditUnion.participantName;
        }
    }

    getUserInfo() {
        this.userInfo = this.localStorageService.getData('user-info');

        if (this.userInfo === null) return;

        this.getUserCU();
    }

    getFirstLetters(name: string) {
        if (!name) return;

        return name.split(' ').map(word => word.charAt(0)).join('');
    }
}