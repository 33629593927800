<p-sidebar [(visible)]="isVisible" (onHide)="hideSidebar()">
    <ng-template pTemplate="header">
        <h1 class="text-xl">AVANA CUSO Report</h1>
    </ng-template>
    <ng-template pTemplate="content">
        <div>
            <ul>
                <li class="py-2 border-b" *ngFor="let report of reports; let last = last" [ngClass]="{'border-none': last}">
                    <div class="flex items-center p-3 hover:bg-secondary rounded-md cursor-pointer">
                        <span class="material-symbols-outlined mr-2">layers</span>
                        <span (click)="goToReportPage(report.dbName)">{{report.title}}</span>
                    </div>
                </li>
            </ul>
        </div>
    </ng-template>
</p-sidebar>