import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { AuthService } from 'src/app/modules/Auth/services/auth.service';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { UserService } from 'src/app/shared/services/user.service';

import { CreditUnionDialogComponent } from '../credit-union-dialog/credit-union-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DialogService]
})
export class HeaderComponent {

  items: MenuItem[] = [
    { label: 'Change Credit Union', icon: 'pi pi-fw pi-refresh', styleClass: 'custom-menu-item', command: () => this.changeCreditUnion() },
    { label: 'Sign out', icon: 'pi pi-fw pi-sign-out', styleClass: 'custom-menu-item', command: () => this.signout() },
  ];

  constructor(
    private router: Router,
    private sidebarService: SidebarService,
    public authService: AuthService,
    public userService: UserService,
    public dialogService: DialogService
  ) { }

  openReportsList() {
    this.sidebarService.openSidebar();
  }

  goToHomePage() {
    this.router.navigate(['/']);
  }

  goToCompliance() {
    this.router.navigate(['/compliance']);
  }

  signout() {
    this.authService.signout();
  }

  getFirstLetters() {
    return this.userService.getFirstLetters(this.userService.userInfo.fullName as string);
  }

  handleMenu() {
    this.items = [
      { label: `Hey ${this.userService.userInfo?.fullName}`, styleClass: 'custom-menu-name' },
      { label: 'Manage Credit Union Users', icon: 'pi pi-fw pi-bars', styleClass: 'custom-menu-item', routerLink: '/user-management', visible: this.userService.userInfo.portalAdmin || this.userService.userInfo.hasCreditUnionAdminAccess },
      { label: 'Change Credit Union', icon: 'pi pi-fw pi-refresh', styleClass: 'custom-menu-item', command: () => this.changeCreditUnion() },
      { label: 'Sign out', icon: 'pi pi-fw pi-sign-out', styleClass: 'custom-menu-item', command: () => this.signout() },
    ];
  }

  changeCreditUnion() {
    this.dialogService.open(CreditUnionDialogComponent, {
      header: 'Select a Credit Union',
      width: '400px',
      height: '250px',
      closeOnEscape: true,
      dismissableMask: true,
      baseZIndex: 100,
      contentStyle: { overflow: 'visible' },
    });
  }
}

