import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "@environments/environment";

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
    private baseUrl = `${environment.apiUrl}`;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const apiRequest = req.clone({
            url: `${this.baseUrl}${req.url}`,
        });

        return next.handle(apiRequest);
    }
}

export const BaseUrlInterseptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: BaseUrlInterceptor,
    multi: true
}