import { HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, EMPTY } from "rxjs";

import { AuthService } from "src/app/modules/Auth/services/auth.service";
import { NotificationService } from "./notification.service";
import { ErrorService } from "./error.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private notificationService: NotificationService,
        private errorService: ErrorService,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        return next.handle(req).pipe(
            catchError((error: any) => {
                const title = error.error.title;
                const detail: string = error.error.detail;
                const expiredSession: string = 'USER_ACCOUNT_SESSION_EXPIRED';

                if ((title === 'Unauthorized' && detail.includes('Jwt expired')) || detail === expiredSession) {
                    this.authService.signout();
                    this.notificationService.showError(this.errorService.ERROR_DATA[expiredSession]);
                } else if (req.url.includes('auth/es-auto') && detail in this.errorService.ERROR_DATA) {
                    this.notificationService.showError(this.errorService.ERROR_DATA[detail]);
                    this.router.navigate(['login'], { relativeTo: this.route.parent });
                } else if (detail in this.errorService.ERROR_DATA && title === 'Unauthorized') {
                    this.authService.signout();
                    this.notificationService.showError(this.errorService.ERROR_DATA[detail]);
                } else if (req.url.includes('reports-archive') && error.status === 404) {
                    this.notificationService.showError(this.errorService.ERROR_DATA['ARCHIVE_NOT_FOUND']);
                } else if (detail in this.errorService.ERROR_DATA) {
                    this.notificationService.showError(this.errorService.ERROR_DATA[detail]);
                } else {
                    this.notificationService.showError(detail);
                }
                return EMPTY
            })
        );
    }
}

export const ErrorInterseptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
}