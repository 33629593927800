import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, tap } from "rxjs";
import { JwtPayload, jwtDecode } from "jwt-decode";

import { AuthRequest, AuthResponse, OtpRequest, OtpResponse } from "../models/auth.model";
import { UserActivation, UserPasswordReset, VerifyLinkResponse } from "../models/password-reset.model";

import { UserService } from "src/app/shared/services/user.service";
import { LocalStorageService } from "src/app/shared/services/local-storage.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _isUserLoggedIn$ = new BehaviorSubject<boolean>(false);
    private readonly TOKEN_NAME = 'profile token';

    isUserLoggedIn = this._isUserLoggedIn$.asObservable();

    get token(): any {
        return localStorage.getItem(this.TOKEN_NAME);
    }

    constructor(
        private http: HttpClient,
        private router: Router,
        private userService: UserService,
        private localStorageService: LocalStorageService
    ) {
        this._isUserLoggedIn$.next(!!this.token);
    }

    getOTP(body: OtpRequest) {
        return this.http.post<OtpResponse>(`/pub/auth/request-totp`, body);
    }

    login(body: AuthRequest) {
        return this.http.post<AuthResponse>(`/pub/auth`, body).pipe(
            tap((res) => {
                this.invokeLoginAction(res);
            })
        );
    }

    autoLogin(data: string, signature: string) {
        return this.http.post<AuthResponse>(`/pub/auth/es-auto?d=${data}&s=${signature}`, null).pipe(
            tap((res) => {
                this.invokeLoginAction(res);
            })
        );
    }

    invokeLoginAction(res: AuthResponse) {
        this.userService.userInfo = jwtDecode<JwtPayload>(res.token);
        this.userService.userEmail = res.username;
        this.userService.cuAccessData = res.cuAccessData;
        this._isUserLoggedIn$.next(true);
        localStorage.setItem(this.TOKEN_NAME, res.token);
        this.localStorageService.setData('user-info', this.userService.userInfo);
    }

    signout() {
        this._isUserLoggedIn$.next(false);
        localStorage.removeItem(this.TOKEN_NAME);
        localStorage.removeItem('user-info');
        this.router.navigate(['/account/login']);
    }

    sendPasswordResetLink(username: string) {
        return this.http.post(`/pub/password-reset/${username}/send-link`, username);
    }

    verifyLink(link: string) {
        return this.http.post<VerifyLinkResponse>(`/pub/password-reset/verify-link?l=${link}`, null);
    }

    resetPassword(body: UserPasswordReset) {
        return this.http.post(`/pub/password-reset`, body);
    }

    verifyUserActivationLink(link: string) {
        return this.http.post<VerifyLinkResponse>(`/pub/user-activation/verify-link?l=${link}`, null);
    }

    activateUser(body: UserActivation) {
        return this.http.post(`/pub/user-activation`, body);
    }
}