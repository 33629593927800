import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';
import { SidebarData } from '../../models/sidebar-data.model';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  reports!: SidebarData[];
  isVisible: boolean = true;

  constructor(
    private sidebarService: SidebarService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    const reportsData = this.sidebarService.getSidebarData();
    this.showReports(reportsData);
  }

  showReports(reportsData: SidebarData[]) {
    if (this.userService.selectedCreditUnion.role === 'CREDIT_UNION_USER') {
      this.reports = reportsData.filter(item => this.userService.selectedCreditUnion.availableReports.includes(item.dbName));
    } else {
      this.reports = reportsData;
    }
  }

  goToReportPage(name: string) {
    this.router.navigate(['/report', name]);
    this.hideSidebar();
  }

  hideSidebar() {
    this.sidebarService.closeSidebar();
  }
}
