import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { AvatarModule } from 'primeng/avatar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';

import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from "./components/header/header.component";
import { CreditUnionDialogComponent } from './components/credit-union-dialog/credit-union-dialog.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        CreditUnionDialogComponent
    ],
    imports: [
        CommonModule,
        AvatarModule,
        TieredMenuModule,
        DynamicDialogModule,
        DropdownModule,
    ],
    exports: [
        HeaderComponent,
        FooterComponent
    ]
})
export class CoreModule { }