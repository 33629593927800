export enum ErrorCodesEnum {
    USER_ACCOUNT_INACTIVE = "Account is inactive.",
    USER_ACCOUNT_PENDING_ACTIVATION = "Account activation is pending.",
    USER_ACCOUNT_LOCKED = "Account is locked. Please contact support.",
    USER_ACCOUNT_SESSION_EXPIRED = "Your session has expired. Please log in again.",
    USER_ACCOUNT_PASSWORD_EXPIRED = "Your password has expired. Please reset it.",
    USER_ACCOUNT_INVALID_PASSWORD = "Invalid password entered. Please try again.",
    USER_ACCOUNT_NOT_FOUND = "Account not found.Please check your details.",
    USER_ACCOUNT_ACCESS_NOT_FOUND = "Access to account not found.",
    USER_ACCOUNT_ALREADY_ATTACHED_TO_CU = "Account is already linked to another Credit Union.",
    CREDIT_UNION_NOT_FOUND = "Credit Union not found in the system.",
    USER_ACCOUNT_STATUS_UNACCEPTABLE = "Account status is unacceptable for this action.",
    USER_CANNOT_MODIFY_ITSELF = "You cannot modify your own account.",
    CREDIT_UNION_ACCESS_DENIED = "Access to Credit Union denied.",
    INVALID_OTP_CODE = "Invalid OTP(One - Time Password) code entered.",
    INVALID_BEARER_TOKEN = "Bearer token is invalid.Please try again.",
    BEARER_TOKEN_INVALIDATED = "Bearer token has been invalidated.",
    ID_CLAIM_IS_NOT_PRESENT = "ID claim is missing.",
    SUBJECT_CLAIM_IS_NOT_PRESENT = "Subject claim is missing.",
    SUBJECT_CLAIM_INVALID_FORMAT = "Subject claim has an invalid format.",
    PORTAL_ADMIN_CLAIM_IS_NOT_PRESENT = "Portal admin claim is missing.",
    PORTAL_ADMIN_CLAIM_INVALID_FORMAT = "Portal admin claim has an invalid format.",
    INVALID_PASSWORD_RESET_LINK = "Invalid password reset link.",
    INVALID_PASSWORD_THE_SAME_AS_OLD = "New password cannot be the same as the old one.",
    INVALID_USER_ACTIVATION_LINK = "Invalid user activation link.",
    PASSWORD_RESET_LINK_EXPIRED = "Password reset link has expired.",
    USER_ACTIVATION_LINK_EXPIRED = "User activation link has expired.",
    UNABLE_TO_DECRYPT_OR_PARSE_LINK = "Unable to decrypt or parse the provided link.",
    ARCHIVE_NOT_FOUND = "There is no available data for the requested period for this Credit Union. It appears that the legacy portal did not contain any information for this timeframe. Please try a different date range for reports after July 2024 or contact support for further assistance.",
    REPORT_ACCESS_FOR_CU_NOT_ALLOWED = "Access to the requested report is not allowed."
}