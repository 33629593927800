import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AuthService } from "./auth.service";

@Injectable()
export class JWTInterseptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isPublicUrl = req.url.includes('pub');

        if (!isPublicUrl) {
            req = req.clone({
                setHeaders: { 'Authorization': `Bearer ${this.authService.token}` }
            });
        }

        return next.handle(req);
    }
}

export const JWTInterseptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: JWTInterseptor,
    multi: true
}