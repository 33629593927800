<header class="h-20 bg-main">
    <div class="container mx-auto p-3">
        <div class="flex justify-between items-center">
            <div class="flex items-center">
                <img src="assets/images/Logo_AVANACUSO.png" alt="Logo_AVANACUSO" class="w-24 cursor-pointer"
                    (click)="goToHomePage()">
                <span *ngIf="authService.isUserLoggedIn | async"
                    class="ml-10 text-white p-2 cursor-pointer hover:bg-main_hover hover:rounded"
                    (click)="openReportsList()">Reports</span>
                <span *ngIf="authService.isUserLoggedIn | async" class="ml-10 text-white p-2 cursor-pointer hover:bg-main_hover hover:rounded" (click)="goToCompliance()">
                    Compliance
                </span>
            </div>
            <span *ngIf="authService.isUserLoggedIn | async"
                class="text-white absolute left-1/2 -translate-x-2/4 hidden lg:block">{{userService.userInfo.selectedCuName}}</span>
            <div *ngIf="authService.isUserLoggedIn | async">
                <p-avatar [label]="getFirstLetters()" size="large" shape="circle" (click)="menu.toggle($event)"
                    class="cursor-pointer"></p-avatar>
                <p-tieredMenu #menu [popup]="true" [model]="items" styleClass="custom-tired-menu"
                    (onShow)="handleMenu()"></p-tieredMenu>
            </div>
        </div>
    </div>
</header>