import { Injectable } from "@angular/core";

import { ErrorCodesEnum } from "../enums/error-codes.enum";

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    readonly ERROR_DATA: Record<string, ErrorCodesEnum> = {
        "USER_ACCOUNT_INACTIVE": ErrorCodesEnum.USER_ACCOUNT_INACTIVE,
        "USER_ACCOUNT_PENDING_ACTIVATION": ErrorCodesEnum.USER_ACCOUNT_PENDING_ACTIVATION,
        "USER_ACCOUNT_LOCKED": ErrorCodesEnum.USER_ACCOUNT_LOCKED,
        "USER_ACCOUNT_SESSION_EXPIRED": ErrorCodesEnum.USER_ACCOUNT_SESSION_EXPIRED,
        "USER_ACCOUNT_PASSWORD_EXPIRED": ErrorCodesEnum.USER_ACCOUNT_PASSWORD_EXPIRED,
        "USER_ACCOUNT_INVALID_PASSWORD": ErrorCodesEnum.USER_ACCOUNT_INVALID_PASSWORD,
        "USER_ACCOUNT_NOT_FOUND": ErrorCodesEnum.USER_ACCOUNT_NOT_FOUND,
        "USER_ACCOUNT_ACCESS_NOT_FOUND": ErrorCodesEnum.USER_ACCOUNT_ACCESS_NOT_FOUND,
        "USER_ACCOUNT_ALREADY_ATTACHED_TO_CU": ErrorCodesEnum.USER_ACCOUNT_ALREADY_ATTACHED_TO_CU,
        "CREDIT_UNION_NOT_FOUND": ErrorCodesEnum.CREDIT_UNION_NOT_FOUND,
        "USER_ACCOUNT_STATUS_UNACCEPTABLE": ErrorCodesEnum.USER_ACCOUNT_STATUS_UNACCEPTABLE,
        "USER_CANNOT_MODIFY_ITSELF": ErrorCodesEnum.USER_CANNOT_MODIFY_ITSELF,
        "CREDIT_UNION_ACCESS_DENIED": ErrorCodesEnum.CREDIT_UNION_ACCESS_DENIED,
        "INVALID_OTP_CODE": ErrorCodesEnum.INVALID_OTP_CODE,
        "INVALID_BEARER_TOKEN": ErrorCodesEnum.INVALID_BEARER_TOKEN,
        "BEARER_TOKEN_INVALIDATED": ErrorCodesEnum.BEARER_TOKEN_INVALIDATED,
        "ID_CLAIM_IS_NOT_PRESENT": ErrorCodesEnum.ID_CLAIM_IS_NOT_PRESENT,
        "SUBJECT_CLAIM_IS_NOT_PRESENT": ErrorCodesEnum.SUBJECT_CLAIM_IS_NOT_PRESENT,
        "SUBJECT_CLAIM_INVALID_FORMAT": ErrorCodesEnum.SUBJECT_CLAIM_INVALID_FORMAT,
        "PORTAL_ADMIN_CLAIM_IS_NOT_PRESENT": ErrorCodesEnum.PORTAL_ADMIN_CLAIM_IS_NOT_PRESENT,
        "PORTAL_ADMIN_CLAIM_INVALID_FORMAT": ErrorCodesEnum.PORTAL_ADMIN_CLAIM_INVALID_FORMAT,
        "INVALID_PASSWORD_RESET_LINK": ErrorCodesEnum.INVALID_PASSWORD_RESET_LINK,
        "INVALID_PASSWORD_THE_SAME_AS_OLD": ErrorCodesEnum.INVALID_PASSWORD_THE_SAME_AS_OLD,
        "INVALID_USER_ACTIVATION_LINK": ErrorCodesEnum.INVALID_USER_ACTIVATION_LINK,
        "PASSWORD_RESET_LINK_EXPIRED": ErrorCodesEnum.PASSWORD_RESET_LINK_EXPIRED,
        "USER_ACTIVATION_LINK_EXPIRED": ErrorCodesEnum.USER_ACTIVATION_LINK_EXPIRED,
        "UNABLE_TO_DECRYPT_OR_PARSE_LINK": ErrorCodesEnum.UNABLE_TO_DECRYPT_OR_PARSE_LINK,
        "ARCHIVE_NOT_FOUND": ErrorCodesEnum.ARCHIVE_NOT_FOUND,
        "REPORT_ACCESS_FOR_CU_NOT_ALLOWED": ErrorCodesEnum.REPORT_ACCESS_FOR_CU_NOT_ALLOWED
    }
}