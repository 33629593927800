import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { CuAccessData } from 'src/app/modules/Auth/models/auth.model';

import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-credit-union-dialog',
  templateUrl: './credit-union-dialog.component.html',
  styleUrls: ['./credit-union-dialog.component.scss']
})
export class CreditUnionDialogComponent {

  selectedItem!: CuAccessData;
  isCUDifferent: boolean = false;

  constructor(
    private dialogRef: DynamicDialogRef,
    private router: Router,
    private localStorageService: LocalStorageService,
    public userService: UserService
  ) { }

  onClose() {
    this.dialogRef.close()
  }

  onChangeCU(creditUnion: any) {
    this.selectedItem = creditUnion.value;

    if (this.selectedItem.charterNumber === this.userService.userInfo.selectedCuCharterNumber) {
      this.isCUDifferent = false;
    } else {
      this.isCUDifferent = true;
    }
  }

  saveNewCU() {
    this.userService.saveNewCU(this.selectedItem.charterNumber).subscribe(() => {
      this.userService.selectedCreditUnion = this.selectedItem;
      this.userService.userInfo.selectedCuName = this.selectedItem.participantName;
      this.userService.userInfo.selectedCuCharterNumber = this.selectedItem.charterNumber;
      this.localStorageService.setData('user-info', this.userService.userInfo);
      this.onClose();
      this.router.navigate(['/']);  
    });
  }
}
