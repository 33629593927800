import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { SidebarData } from "../models/sidebar-data.model";

@Injectable({
    providedIn: 'root'
})
export class SidebarService {
    private _sidebarVisible$ = new BehaviorSubject<boolean>(false);
    isSidebarVisible = this._sidebarVisible$.asObservable();

    private readonly SIDEBAR_DATA: SidebarData[] = [
        { dbName: "CRE_InvTransSummry", title: "CRE Investor Transaction Summary", apiName: "cre-inv-trans-summary", hasTotals: true },
        { dbName: "CRE_RemitSummry", title: "CRE Investor Remittance Summary", apiName: "cre-remittance-summary", hasTotals: true },
        { dbName: "CRE_TrialBal", title: "CRE Investor Trial Balance", apiName: "cre-trial-bal", hasTotals: true },
        { dbName: "CRE_Delinquency", title: "CRE Delinquency", apiName: "cre-delinquency", hasTotals: true },
        { dbName: "CRE_Forbearance", title: "CRE Forbearance", apiName: "cre-forbearance", hasTotals: true },
        { dbName: "CRE_LeadLenderServ", title: "CRE Lead Lender Servicing", apiName: "cre-lead-lender-serv", hasTotals: true },
        { dbName: "CRE_LoanModifn", title: "CRE Loan Modification", apiName: "cre-loan-modification", hasTotals: false },
        { dbName: "CRE_PaidInFull", title: "CRE Paid In Full", apiName: "cre-paid-in-full", hasTotals: true },
        { dbName: "CRE_NewLoans", title: "CRE New Loans", apiName: "cre-new-loans", hasTotals: true },
        { dbName: "CRE_PP_LoanBal", title: "CRE Participant Loan Balance", apiName: "cre-pp-loan-bal", hasTotals: true },
        { dbName: "CRE_PP_Serv", title: "CRE Participant Servicing", apiName: "cre-participant-servicing", hasTotals: true },
        { dbName: "CRE_PastMaturity", title: "CRE Past Maturity", apiName: "cre-past-maturity", hasTotals: true },
        { dbName: "CRE_PrepaidLoans", title: "CRE Prepaid Loans", apiName: "cre-prepaid-loans", hasTotals: true },
        { dbName: "CRE_RiskRating", title: "CRE Risk Rating", apiName: "cre-risk-rating", hasTotals: false },
        { dbName: "CRE_Covid19Deff", title: "Covid-19 Interest Deferral", apiName: "covid19", hasTotals: true },
        { dbName: "CRE_ALM", title: "ALM Report", apiName: "cre-alm", hasTotals: false },
    ];

    getSidebarData() {
        return this.SIDEBAR_DATA;
    }

    findReportData(name: string) {
        return this.SIDEBAR_DATA.find(value => value.dbName === name);
    }

    openSidebar() {
        this._sidebarVisible$.next(true);
    }

    closeSidebar() {
        this._sidebarVisible$.next(false);
    }
}