import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { JWTInterseptorProvider } from './modules/Auth/services/jwt.interseptor';
import { ErrorInterseptorProvider } from './shared/services/error.interceptor';
import { BaseUrlInterseptorProvider } from './shared/services/base-url.interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    ToastModule
  ],
  providers: [
    JWTInterseptorProvider,
    ErrorInterseptorProvider,
    BaseUrlInterseptorProvider,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
