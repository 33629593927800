import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { UserService } from 'src/app/shared/services/user.service';

export const roleGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const userService = inject(UserService);
  const router = inject(Router);

  const isAuthorized = userService.userInfo.portalAdmin === route.data['admin'] || userService.userInfo.hasCreditUnionAdminAccess === route.data['admin'];

  if (isAuthorized) {
    return true;
  } else {
    router.navigate(['']);
    return false;
  }
};