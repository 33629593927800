import { NgModule } from "@angular/core";

import { SidebarModule } from 'primeng/sidebar';

import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [
        SidebarComponent
    ],
    imports: [
        CommonModule,
        SidebarModule
    ],
    exports: [
        CommonModule,
        SidebarComponent
    ]
})
export class SharedModule {}