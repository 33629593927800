import { Component, OnInit } from '@angular/core';

import { UserService } from './shared/services/user.service';
import { SidebarService } from './shared/services/sidebar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private userService: UserService,
    public sidebarService: SidebarService
  ) {}

  ngOnInit(): void {
    this.userService.getUserInfo();
  }
}
